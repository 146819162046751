<!-- 自提详情 -->
<template>
  <div>
    <back />
    <div class="det">
      <!-- 基本信息 v-loading="loading"-->
      <div class="detail">
        <h4 class="title">基本信息：</h4>
        <el-row class="row">
          <el-col :span="5">
            <div class="col order-code">缴费编号：{{ basicInfo.feeCode }}</div>
          </el-col>
          <el-col :span="5">
            <div class="col">结算时间：{{ basicInfo.createdTime }}</div>
          </el-col>
          <el-col :span="5">
            <div class="col">结算周期：{{ `${basicInfo.startDate}-${basicInfo.endDate}` }}</div>
          </el-col>
          <el-col :span="5">
            <div class="col">销售总额(元)：{{ Number(basicInfo.settlementAmount).toFixed(2) }}元</div>
          </el-col>
          <el-col :span="4">
            <div class="col">服务费金额(元)：{{ Number(basicInfo.feeAmount).toFixed(2) }}元</div>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="5">
            <div class="col order-code">缴费状态：{{ basicInfo.status == 0 ? '未缴费' : basicInfo.status == 1 ? '已缴费' : '已完成' }}
            </div>
          </el-col>
          <el-col :span="5">
            <div class="col">缴费时间：{{ basicInfo.payTime }}</div>
          </el-col>
        </el-row>

      </div>
      <!-- 商品信息 -->
      <div class="detail">
        <h4 class="title">商品信息：</h4>
        <!-- 表格 -->
        <div class="globle_table tableBox">
          <el-table :data="tableData" max-height="540">
            <el-table-column label="序号" min-width="60" align="center">
              <template slot-scope="scope">
                <!-- {{ scope.$index + 1 + (currentPage - 1) * pageSize }}  -->
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <!-- <el-table-column prop="productCode" label="商品编号" show-overflow-tooltip min-width="150" align="center">
            </el-table-column> -->
            <el-table-column prop="barCode" label="商品条形码" show-overflow-tooltip min-width="150" align="center">
            </el-table-column>
            <el-table-column prop="productName" label="商品名称" show-overflow-tooltip min-width="150" align="center">
            </el-table-column>
            <el-table-column prop="specs" label="规格" show-overflow-tooltip min-width="80" align="center">
            </el-table-column>
            <el-table-column prop="categoryName" label="商品类目" show-overflow-tooltip min-width="150" align="center">
            </el-table-column>
            <el-table-column prop="brand" label="商品品牌" show-overflow-tooltip min-width="100" align="center">
            </el-table-column>
            <el-table-column prop="settlementAmount" label="结算金额(元)" show-overflow-tooltip min-width="110"
              align="center">
            </el-table-column>
            <el-table-column prop="feeRate" label="费率" show-overflow-tooltip min-width="80" align="center">
              <template slot-scope="scope">
                {{ `${scope.row.feeRate} %` }}
              </template>
            </el-table-column>
            <el-table-column prop="feeAmount" label="费用" show-overflow-tooltip fixed="right" min-width="100"
              align="center">
            </el-table-column>
          </el-table>
        </div>
      </div>

    </div>
    <!-- 分页 -->
    <!-- <div class="pagination">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
        layout="prev, pager, next, jumper, sizes, total" :total="totalCount">
      </el-pagination>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      basicInfo: {},//基本信息
      tableData: [],
      id: '',//订单id
      // 分页器
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50],
      totalCount: 0, // 总记录数据
    }
  },

  created() {
    this.id = this.$route.query.id//订单id
    if (this.id) this.getRequest1()
  },
  methods: {
    // 请求
    getRequest1() {
      this.loading = true;
      this.$axios.get(this.$api.techFeeInfo, {
        params: {
          id: this.id,//订单id
        }
      }).then((res) => {
        const { code, result } = res.data
        if (code == 100) {
          this.basicInfo = result
          this.tableData = result.techFeeDetailVos
          this.totalCount = result.totalCount
          this.loading = false;
        }
      }).catch((err) => {
        console.log(err);
        this.loading = false;
      })
    },
    // 分页器
    onCurrentPage() {
      this.tableData = []
      this.currentPage = 1
      this.getRequest1()
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.onCurrentPage()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val
      this.tableData = []
      this.getRequest1()
    },


  },
}
</script>

<style scoped lang='scss'>
.det {
  padding: 0 30px;

  .detail {
    padding-top: 20px;
    border-bottom: 1px solid rgba(3, 16, 14, 0.06);

    .title {
      font-size: 16px;
      margin-bottom: 15px;
    }

    .row {
      color: #707070;
      // font-size: 16px;

      .col {
        margin-bottom: 20px;
        // margin-right: 10px;
        font-size: 16px;
      }

      .order-code {
        padding-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .pagination {
      margin-bottom: 30px;
    }

  }



}
</style>